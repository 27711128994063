import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import logo from "./logo.png";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from "react-router-dom";
import {Container, Typography, AppBar, Toolbar, IconButton, Tooltip, Grid, Button} from "@mui/material";
import {logout} from "./functions";
import config from "./config.json";
import LogoutIcon from "@mui/icons-material/Logout";
import {DataGrid} from '@mui/x-data-grid';

export default function Admin() {
    let navigate = useNavigate();

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'firstName',
            headerName: 'First name',
            editable: true,
            flex: 1
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            editable: true,
            flex: 1
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            editable: true,
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Update',
            renderCell: params => (
                <Button>
                    Update
                </Button>
            )
        }
    ];

    return (
        <Container disableGutters maxWidth="md" component="main" sx={{pt: 2, pb: 2}}>
            <AppBar position='static' sx={{bgcolor: 'white'}} color='default' elevation={1}>
                <Toolbar sx={{flexWrap: 'wrap'}}>
                    <Box component="img" src={logo} sx={{height: 25, mr: 1}}/>
                    <Typography variant="overline" color="inherit" noWrap sx={{flexGrow: 1, mt: 1}}>
                        Customer Portal
                    </Typography>

                    <Tooltip title="Home">
                        <IconButton sx={{marginLeft: 1}} onClick={() => {
                            navigate('/home', {replace: true})
                        }}>
                            <HomeIcon sx={{color: '#00a731'}}/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Logout">
                        <IconButton sx={{marginLeft: 1}} onClick={() => {
                            logout()
                            window.location.href = config.logout_endpoint
                        }}>
                            <LogoutIcon sx={{color: '#D35400'}}/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Grid container spacing={2} sx={{mt: 2}} style={{ height: 500 }}>
                <Grid item xs={12}>
                    <DataGrid columns={columns} rows={rows}/>
                </Grid>
            </Grid>
        </Container>
    )
}